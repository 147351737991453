import React, { useState } from 'react';
import {
	CreateProductBatchContainerStyle,
	CreateProductBatchHeaderStyle,
	CreateProductBatchBodyStyle,
	CreateProductBatchCardStyle,
} from './styles';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import { UaidAPIs } from 'api';
import { Intl } from 'components';

interface CreateProductBatchProps {
	prodId: string;
	refetch: any;
}

const CreateProductBatch = (props: CreateProductBatchProps) => {
	const { prodId, refetch } = props;
	const { createQRBatch } = UaidAPIs();
	const [open, setOpen] = React.useState(false);
	const [quantity, setQuantity] = useState(0);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setQuantity(0);
		setOpen(false);
	};

	return (
		<>
			<Tooltip title={<Intl>product.create.qr.batch</Intl>} arrow>
				<IconButton onClick={handleOpen}>
					<LibraryAddIcon />
				</IconButton>
			</Tooltip>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal1"
				aria-describedby="modal1"
			>
				<Box sx={CreateProductBatchContainerStyle}>
					<Box sx={CreateProductBatchHeaderStyle}>
						<Typography
							sx={{ fontSize: 20, display: 'flex', ml: 2 }}
						>
							{<Intl>product.create.qr.batch</Intl>}
						</Typography>
						<IconButton
							color="inherit"
							onClick={handleClose}
							sx={{ width: 40, height: 40, mr: 1 }}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Divider />
					<Box sx={CreateProductBatchBodyStyle}>
						<Card sx={CreateProductBatchCardStyle} elevation={0}>
							<CardContent>
								<Chip
									label={
										<>
											<Intl>product.id.label</Intl>
											{prodId}
										</>
									}
								/>
								<TextField
									required
									fullWidth
									id="quantity"
									label={
										<Intl>
											product.create.qr.batch.quantity.label
										</Intl>
									}
									type="number"
									defaultValue={0}
									variant="outlined"
									onChange={(e) =>
										setQuantity(parseInt(e.target.value))
									}
								/>
							</CardContent>
							<CardActions
								sx={{
									justifyContent: 'flex-end',
									pr: 2,
									pb: 3,
								}}
							>
								<Button
									variant="contained"
									onClick={() => {
										createQRBatch(prodId, quantity).then(
											() => {
												handleClose();
												refetch();
											}
										);
									}}
								>
									{<Intl>common.submit</Intl>}
								</Button>
							</CardActions>
						</Card>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default CreateProductBatch;
