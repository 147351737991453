import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { supportedLanguageList } from 'locales';
import TranslateIcon from '@mui/icons-material/Translate';
import ProjectConfigs from 'configs';
import i18n from 'i18next';

const LanguageDropdownList = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const highlightCurrentLanguage = (value: string) => {
		let currentLanguage = ProjectConfigs.defaultLanguage;
		const localStorageLanguage = localStorage.getItem('i18nextLng');
		if (localStorageLanguage) {
			currentLanguage = localStorageLanguage.includes('en')
				? 'en'
				: localStorageLanguage;
		}
		return value === currentLanguage;
	};

	return (
		<>
			<Button
				variant="contained"
				aria-label="more"
				id="language-dropdown-button"
				aria-controls={open ? 'language-dropdown-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
				disableRipple
				sx={{
					ml: 1,
					background: 'white',
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'white',
					},
				}}
			>
				<TranslateIcon
					sx={{ color: 'var(--accessreal-theme-color)' }}
				/>
			</Button>
			<Menu
				id="language-dropdown-menu"
				MenuListProps={{
					'aria-labelledby': 'language-dropdown-button',
				}}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
				sx={{
					mt: 0.5,
				}}
			>
				{Object.keys(supportedLanguageList).map((value) => (
					<MenuItem
						key={value}
						selected={highlightCurrentLanguage(value)}
						sx={{
							pl: 2,
							pr: 3,
						}}
						onClick={() => {
							handleClose();
							i18n.changeLanguage(value).then();
						}}
					>
						{supportedLanguageList[value]}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default LanguageDropdownList;
