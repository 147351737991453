import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApiIcon from '@mui/icons-material/Api';

import {
	AuthenticationButton,
	BrandLogo,
	LanguageDropdownList,
	ContentWrapper,
	Intl,
} from 'components';
import { RouterLink } from 'utils';

const AppContent = () => {
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const setMobileClose = () => {
		setMobileOpen(false);
	};

	const drawer = (
		<>
			<Toolbar />
			<Divider />
			<Box sx={{ overflow: 'auto' }}>
				<List>
					<RouterLink
						to="/"
						text={<Intl>nav.home</Intl>}
						icon={<DashboardIcon />}
						onClick={setMobileClose}
					/>
					<Divider />
					<RouterLink
						to="/product/create"
						text={<Intl>nav.product.create</Intl>}
						icon={<AddCircleOutlineIcon />}
						onClick={setMobileClose}
					/>
					<RouterLink
						to="/product/list"
						text={<Intl>nav.product.list</Intl>}
						icon={<FormatListBulletedIcon />}
						onClick={setMobileClose}
					/>
					<Divider />
					<RouterLink
						to="/qrcode/generate"
						text={<Intl>nav.qrcode.generate</Intl>}
						icon={<AddCircleOutlineIcon />}
						onClick={setMobileClose}
					/>
					<RouterLink
						to="/qrcode/list"
						text={<Intl>nav.qrcode.list</Intl>}
						icon={<FormatListBulletedIcon />}
						onClick={setMobileClose}
					/>
					<Divider />
					<RouterLink
						to="/account/profile"
						text={<Intl>nav.account.profile</Intl>}
						icon={<AccountCircleIcon />}
						onClick={setMobileClose}
					/>
					<RouterLink
						to="/account/external-api"
						text={<Intl>nav.account.external-api</Intl>}
						icon={<ApiIcon />}
						onClick={setMobileClose}
					/>
				</List>
			</Box>
		</>
	);

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					background: 'var(--accessreal-theme-color)',
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 1, display: { sm: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ flexGrow: 1 }}
					>
						<BrandLogo />
					</Typography>
					<AuthenticationButton />
					<LanguageDropdownList />
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{
					width: { sm: 'var(--drawer-width)' },
					flexShrink: { sm: 0 },
				}}
				aria-label="mailbox folders"
			>
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': {
							width: 'var(--drawer-width)',
							boxSizing: 'border-box',
							background: '#f0f0f0',
							borderRight: '1px solid #e9e9e9',
							boxShadow:
								'0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 8px 12px 1px rgb(0 0 0 / 14%), 0px 3px 15px 2px rgb(0 0 0 / 12%)',
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': {
							width: 'var(--drawer-width)',
							boxSizing: 'border-box',
							background: '#f0f0f0',
							borderRight: '1px solid #e9e9e9',
							boxShadow:
								'0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 8px 12px 1px rgb(0 0 0 / 14%), 0px 3px 15px 2px rgb(0 0 0 / 12%)',
						},
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					width: {
						xs: '100%',
						sm: 'calc(100% - var(--drawer-width))',
					},
				}}
			>
				<Toolbar />
				<ContentWrapper />
			</Box>
		</Box>
	);
};

export default AppContent;
