import React from 'react';
import { GetMessageAPIs } from 'api';
import { CodeSnippet } from 'components';

const ExternalApi = () => {
	const {
		apiEndpoint,
		apiResponse,
		getPublicResource,
		getProtectedResource,
		getRbacResource,
	} = GetMessageAPIs();

	return (
		<div className="container">
			<h1>External API</h1>
			<p>
				Use the buttons below to request resources from an API server.
				<br />
				Each API endpoint has a different access control level.
				<br />
				<strong>Only authenticated users can access this page.</strong>
			</p>
			<div
				className="btn-group mt-5"
				role="group"
				aria-label="External API Requests Examples"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={getPublicResource}
				>
					Public
				</button>
				<button
					type="button"
					className="btn btn-primary"
					onClick={getProtectedResource}
				>
					Protected
				</button>
				<button
					type="button"
					className="btn btn-primary"
					onClick={getRbacResource}
				>
					RBAC
				</button>
			</div>
			<CodeSnippet title={apiEndpoint} code={apiResponse} />
		</div>
	);
};

export default ExternalApi;
