import React, { useEffect } from 'react';
import { useImage } from 'utils';
import { Intl } from 'components';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import { UaidAPIs } from 'api';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { QRCodeCanvas } from 'qrcode.react';

const Home = () => {
	const { getImage } = useImage();
	const { acquireDefaultQR, cancelRequest } = UaidAPIs();
	const { user } = useAuth0();

	useEffect(() => {
		return () => {
			cancelRequest();
		};
		//	Keep deps as [] will make useEffect run only once.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { data } = useQuery(
		['defaultExposeCode'],
		async () => {
			const postsData = await acquireDefaultQR(user!['company']);
			for (const [key, value] of Object.entries(postsData)) {
				if (key === 'data') {
					const dataObj = value;
					for (const [key, value] of Object.entries(dataObj)) {
						if (key === 'ecode') {
							return value;
						}
					}
				}
			}
			throw new Error('Request failed');
		},
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: true,
			retryDelay: 5000,
		}
	);

	const defaultExposeCode = data ? data : null;

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					pb: 5,
				}}
			>
				<h2>{<Intl>home.default.qr</Intl>}</h2>
				{defaultExposeCode && typeof defaultExposeCode === 'string' ? (
					<QRCodeCanvas value={defaultExposeCode} />
				) : (
					<CircularProgress />
				)}
			</Box>
			<Divider sx={{ mt: 1, mb: 1 }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
			>
				<Box
					component="img"
					sx={{
						width: '100%',
						maxWidth: 700,
					}}
					alt="Anti_Counterfeiting"
					src={getImage({
						imageName: 'Anti_Counterfeiting.jpg',
					})}
				/>
			</Box>
		</>
	);
};

export default Home;
