import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton, SignupButton, SignoutButton } from 'components';

const AuthenticationButton = () => {
	const { isAuthenticated } = useAuth0();
	return isAuthenticated ? (
		<SignoutButton />
	) : (
		<>
			<SignupButton />
			<LoginButton />
		</>
	);
};

export default AuthenticationButton;
