import images from 'images';
import ProjectConfigs from 'configs';

const useImage = () => {
	const lang: string | null = localStorage.getItem('i18nextLng');

	const getImage = (options: {
		imageName: string;
		language?: string | null;
	}) => {
		if (images.hasOwnProperty(options.imageName)) {
			if (images[options.imageName].hasOwnProperty('common')) {
				return images[options.imageName]['common'];
			} else if (
				options.language &&
				images[options.imageName].hasOwnProperty(options.language)
			) {
				return images[options.imageName][options.language];
			} else if (lang && images[options.imageName].hasOwnProperty(lang)) {
				return images[options.imageName][lang];
			} else {
				return images[options.imageName][
					ProjectConfigs.defaultLanguage
				];
			}
		} else {
			console.log('this image doesnt exist');
		}
	};

	return {
		getImage,
	};
};

export default useImage;
