//	Assumptions:
//  	1. There should be no images under ./images folder.
//		2. images folder should contains ./index.tsx, /common, /{language1}, /{language2} etc.

const importAll = (require: any) =>
	require.keys().reduce((acc: any, next: any) => {
		if (
			next.indexOf('./') !== -1 &&
			next.substring(next.indexOf('/') + 1).indexOf('/') !== -1
		) {
			let file = next.split('/');
			if (!acc.hasOwnProperty(file[2])) {
				acc[file[2]] = {};
			}
			acc[file[2]][file[1]] = require(next);
		}
		return acc;
	}, {});

const images = importAll(require.context('./', true, /\.(png|jpe?g|svg)$/));

export default images;
