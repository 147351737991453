import React from 'react';
import './index.css';

const QRCodeList = () => {
	return (
		<>
			<div>QRCodeList</div>
		</>
	);
};

export default QRCodeList;
