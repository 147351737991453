//	https://notistack.com/api-reference
//	Refer to above link for configuration detail

import React from 'react';
import { Grow } from '@mui/material';
import { SnackbarProvider } from 'notistack';

const SnackbarWrapper = ({ children }: any) => {
	return (
		<SnackbarProvider
			maxSnack={3}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			TransitionComponent={Grow}
			transitionDuration={{ enter: 225, exit: 50 }}
			autoHideDuration={3000}
		>
			{children}
		</SnackbarProvider>
	);
};

export default SnackbarWrapper;
