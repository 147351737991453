//	Assumptions:
//  	1. There should be no sub-folder.

const importAll = (require: any) =>
	require.keys().reduce((acc: any, next: any) => {
		if (next.indexOf('./') !== -1) {
			let file = next.split('/')[1].split('.')[0];
			if (!acc.hasOwnProperty(file)) {
				acc[file] = {};
			}
			acc[file]['translation'] = require(next);
		}
		return acc;
	}, {});

const languageResources = importAll(require.context('./', false, /\.(json)$/));

const supportedLanguageList = (() => {
	let list: any = {};
	for (let language in languageResources) {
		if (!list.hasOwnProperty(language)) {
			list[language] =
				languageResources[language]['translation'][
					'default.languageName'
				];
		}
	}
	return list;
})();

export { languageResources, supportedLanguageList };
