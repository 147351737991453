import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './pages/App';
import { Auth0ProviderWithHistory, SnackbarWrapper } from 'utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import './i18n';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0ProviderWithHistory>
				<SnackbarWrapper>
					<QueryClientProvider client={new QueryClient()}>
						<App />
					</QueryClientProvider>
				</SnackbarWrapper>
			</Auth0ProviderWithHistory>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
