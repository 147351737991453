import React from 'react';
import { useRoutes } from 'react-router-dom';
import {
	Home,
	CreateProduct,
	ProductList,
	GenerateQRCode,
	QRCodeList,
	Profile,
	ExternalApi,
	NotFound,
} from 'pages';

const ContentWrapper = () => {
	const routes = [
		{
			path: '/',
			element: <Home />,
		},
		{
			path: '/product/create',
			element: <CreateProduct />,
		},
		{
			path: '/product/list',
			element: <ProductList />,
		},
		{
			path: '/qrcode/generate',
			element: <GenerateQRCode />,
		},
		{
			path: '/qrcode/list',
			element: <QRCodeList />,
		},
		{
			path: '/account/profile',
			element: <Profile />,
		},
		{
			path: '/account/external-api',
			element: <ExternalApi />,
		},
		{
			path: '*',
			element: <NotFound />,
		},
	];
	return useRoutes(routes);
};

export default ContentWrapper;
