import React from 'react';
import { useTranslation } from 'react-i18next';
import { useImage } from 'utils';

const BrandLogo = () => {
	const { t } = useTranslation();
	const { getImage } = useImage();

	return (
		<picture style={{ display: 'flex' }}>
			<source
				media="(max-width: 599px)"
				srcSet={getImage({ imageName: 'logo_small.png' })}
			/>
			<source
				media="(min-width: 600px)"
				srcSet={getImage({ imageName: 'logo_large.png' })}
			/>
			<img
				style={{ height: '24px', margin: '3px 0' }}
				src={getImage({ imageName: 'logo_small.png' })}
				alt={t('nav.logo')}
			/>
		</picture>
	);
};

export default BrandLogo;
