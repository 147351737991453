export const CreateProductBatchContainerStyle = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90vw',
	maxWidth: '500px',
	// height: '90vh',
	bgcolor: '#ffffff',
	borderRadius: '6px',
	boxShadow: 8,
	// overflow: "hidden",
};

export const CreateProductBatchHeaderStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	height: '50px',
};

export const CreateProductBatchBodyStyle = {
	width: '100%',
	height: 'calc(100% - 56px)',
	display: 'flex',
	justifyContent: 'center',
};

export const CreateProductBatchCardStyle = {
	width: 'calc(100% - 2px)',
	height: '100%',
	// overflowY: "scroll",
	'& .MuiTextField-root': { mt: 2 },
};
