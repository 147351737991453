import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';

import { UaidAPIs } from 'api';
import { Intl } from 'components';

interface DownloadQRFileProps {
	batchId: string;
}

const CreateProductBatch = (props: DownloadQRFileProps) => {
	const { batchId } = props;
	const { downloadQR } = UaidAPIs();
	const handleClick = () => {
		downloadQR(batchId).then();
	};

	return (
		<>
			<Tooltip
				title={<Intl>product.download.qr.tooltip.title</Intl>}
				arrow
			>
				<IconButton onClick={handleClick}>
					<DownloadIcon />
				</IconButton>
			</Tooltip>
		</>
	);
};

export default CreateProductBatch;
