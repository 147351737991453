import { useExternalApi } from 'utils';
import { AxiosRequestConfig } from 'axios';
import { useSnackbar } from 'notistack';

const UaidAPIs = () => {
	const apiServerUrl = process.env.REACT_APP_SERVER_URL;

	const { makeRequest, cancelRequest } = useExternalApi();
	const { enqueueSnackbar } = useSnackbar();

	const acquireDefaultQR = async (companyId: string): Promise<object> => {
		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/business/acquireDefaultQR`,
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			data: {
				category_id: companyId,
			},
		};

		const { data, status } = await makeRequest({
			config,
			authenticated: true,
		});

		if (typeof data !== 'object') {
			enqueueSnackbar(data, { variant: 'warning' });
		} else {
			if (status === 404) {
				enqueueSnackbar('Generating your first QR Code...', {
					variant: 'warning',
				});
			}
		}

		return data;
	};

	const downloadQR = async (batchId: string): Promise<void> => {
		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/business/downloadQR`,
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			data: {
				batch_id: batchId,
			},
			responseType: 'blob',
		};

		const { data, headers } = await makeRequest({
			config,
			authenticated: true,
		});
		const href = URL.createObjectURL(data);

		const link = document.createElement('a');
		link.href = href;
		link.setAttribute(
			'download',
			headers['content-disposition'].split('=')[1]
		);
		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		URL.revokeObjectURL(href);
	};

	const createQRBatch = async (
		prodId: string,
		num: number
	): Promise<void> => {
		enqueueSnackbar('Request Submitted.', { variant: 'info' });

		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/business/createQRBatch`,
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			data: {
				product_id: prodId,
				num: num,
			},
		};

		const { data } = await makeRequest({ config, authenticated: true });

		if (data.code === 0) {
			enqueueSnackbar('Product Created!', { variant: 'success' });
		} else {
			enqueueSnackbar('Unknown Error!', { variant: 'error' });
		}
	};

	return {
		cancelRequest,
		acquireDefaultQR,
		downloadQR,
		createQRBatch,
	};
};

export default UaidAPIs;
