import React from 'react';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Intl } from 'components';

const SignupButton = () => {
	const { loginWithRedirect } = useAuth0();
	return (
		<Button
			variant="contained"
			sx={{
				color: 'black',
				background: 'white',
				'&.MuiButtonBase-root:hover': {
					backgroundColor: 'white',
				},
			}}
			onClick={() =>
				loginWithRedirect({
					screen_hint: 'signup',
					ui_locales: localStorage.getItem('i18nextLng')!,
				})
			}
		>
			<Intl>user.signup</Intl>
		</Button>
	);
};

export default SignupButton;
