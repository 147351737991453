import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ProjectConfigs from 'configs';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: ProjectConfigs.i18nDetectionOption,
		fallbackLng: ProjectConfigs.i18nFallBackLanguage,
		resources: ProjectConfigs.i18nLanguageResources,
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	})
	.then();

export default i18n;
