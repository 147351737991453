import React, { useState } from 'react';
import {
	UpdateProductInfoContainerStyle,
	UpdateProductInfoHeaderStyle,
	UpdateProductInfoBodyStyle,
	UpdateProductInfoCardStyle,
} from './styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import { ProductAPIs } from 'api';
import { Intl } from 'components';

const getValueByLang = (prodName: object) => {
	let lang = localStorage.getItem('i18nextLng')!;
	lang = lang === 'en' ? 'en_US' : lang.replace('-', '_');
	return prodName[lang as keyof typeof getValueByLang];
};

const getDetailFromList = (txt: Array<object>) => {
	let maintenance_period_value = '';
	let qanda_url_value = '';
	let instructions_url_value = '';
	let detailed_url_value = '';
	for (let obj in txt) {
		const detailObj = txt[obj] as rawDetailProps;
		if (detailObj.code === 'maintenance_period') {
			maintenance_period_value = JSON.parse(detailObj.value).value;
		}
		if (detailObj.code === 'qanda_url') {
			qanda_url_value = getValueByLang(JSON.parse(detailObj.value));
		}
		if (detailObj.code === 'instructions_url') {
			instructions_url_value = getValueByLang(
				JSON.parse(detailObj.value)
			);
		}
		if (detailObj.code === 'detailed_url') {
			detailed_url_value = getValueByLang(JSON.parse(detailObj.value));
		}
	}
	const returnDetail: DetailProps = {
		maintenance_period: maintenance_period_value,
		qanda_url: qanda_url_value,
		instructions_url: instructions_url_value,
		detailed_url: detailed_url_value,
	};
	return returnDetail;
};

const getImageFromList = (img: Array<object>) => {
	for (let obj in img) {
		const imgObj = img[obj] as rawImgProps;
		if (imgObj.code === 'img') {
			return (
				'https://ar2demo.accessreal.com/api/file/getImg?name=' +
				imgObj.img
			);
		}
	}
	return '';
};

type DetailProps = React.PropsWithChildren<{
	maintenance_period: string;
	qanda_url: string;
	instructions_url: string;
	detailed_url: string;
}>;

type rawDetailProps = React.PropsWithChildren<{
	code: string;
	value: string;
}>;

type rawImgProps = React.PropsWithChildren<{
	code: string;
	img: string;
}>;

type ProductInfoProps = React.PropsWithChildren<{
	id: string;
	prodName: object;
	txt: Array<object>;
	img: Array<object>;
}>;

type UpdateProductInfoProps = React.PropsWithChildren<{
	productInfo: ProductInfoProps;
	refetch: any;
}>;

const UpdateProductInfo = (props: UpdateProductInfoProps) => {
	const { productInfo, refetch } = props;
	const productDetail = getDetailFromList(productInfo.txt);
	const productImg = getImageFromList(productInfo.img);

	const { updateProductInfo } = ProductAPIs();

	const [open, setOpen] = React.useState(false);
	const [productName, setProductName] = useState('');
	const [maintenancePeriod, setMaintenancePeriod] = useState('');
	const [qandaUrl, setQandaUrl] = useState('');
	const [instructionsUrl, setInstructionsUrl] = useState('');
	const [detailedUrl, setDetailedUrl] = useState('');
	const [productImagePreview, setProductImagePreview] = useState(productImg);
	const [productImage, setProductImage] = useState('');
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setProductName('');
		setMaintenancePeriod('');
		setQandaUrl('');
		setInstructionsUrl('');
		setDetailedUrl('');
		setProductImagePreview(productImg);
		setProductImage('');
		setOpen(false);
	};

	return (
		<>
			<Tooltip title={<Intl>product.edit.details</Intl>} arrow>
				<IconButton onClick={handleOpen}>
					<EditIcon />
				</IconButton>
			</Tooltip>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={UpdateProductInfoContainerStyle}>
					<Box sx={UpdateProductInfoHeaderStyle}>
						<Typography
							sx={{ fontSize: 20, display: 'flex', ml: 2 }}
						>
							{<Intl>product.edit.details</Intl>}
						</Typography>
						<IconButton
							color="inherit"
							onClick={handleClose}
							sx={{ width: 40, height: 40, mr: 1 }}
						>
							<CloseIcon />
						</IconButton>
					</Box>
					<Divider />
					<Box sx={UpdateProductInfoBodyStyle}>
						<Card sx={UpdateProductInfoCardStyle} elevation={0}>
							<CardContent>
								<Chip
									label={
										<>
											<Intl>product.id.label</Intl>
											{productInfo.id}
										</>
									}
								/>
								<TextField
									fullWidth
									id="product-name"
									label={<Intl>product.props.name</Intl>}
									defaultValue={getValueByLang(
										productInfo.prodName
									)}
									variant="outlined"
									onChange={(e) =>
										setProductName(e.target.value)
									}
								/>
								<TextField
									fullWidth
									id="maintenance_period"
									label={
										<Intl>
											product.props.maintenance_period
										</Intl>
									}
									defaultValue={
										productDetail.maintenance_period
									}
									variant="outlined"
									onChange={(e) =>
										setMaintenancePeriod(e.target.value)
									}
								/>
								<TextField
									fullWidth
									id="qanda_url"
									label={<Intl>product.props.qanda_url</Intl>}
									defaultValue={productDetail.qanda_url}
									variant="outlined"
									onChange={(e) =>
										setQandaUrl(e.target.value)
									}
								/>
								<TextField
									fullWidth
									id="instructions_url"
									label={
										<Intl>
											product.props.instructions_url
										</Intl>
									}
									defaultValue={
										productDetail.instructions_url
									}
									variant="outlined"
									onChange={(e) =>
										setInstructionsUrl(e.target.value)
									}
								/>
								<TextField
									fullWidth
									id="detailed_url"
									label={
										<Intl>product.props.detailed_url</Intl>
									}
									defaultValue={productDetail.detailed_url}
									variant="outlined"
									onChange={(e) =>
										setDetailedUrl(e.target.value)
									}
								/>
								<Chip
									label={<Intl>product.props.image</Intl>}
									sx={{
										mt: 2,
										mb: 1,
									}}
								/>
								<Paper
									elevation={2}
									sx={{
										width: '80%',
										aspectRatio: '640/360',
										bgcolor: '#cccccc',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										overflow: 'hidden',
									}}
								>
									<Box
										component="img"
										sx={{
											maxWidth: '100%',
											// aspectRatio: "640/360",
										}}
										alt="Product Image"
										src={productImagePreview}
									/>
								</Paper>

								<Button
									variant="contained"
									component="label"
									sx={{
										mt: 1,
									}}
								>
									{<Intl>product.upload.image</Intl>}
									<input
										hidden
										accept="image/*"
										type="file"
										onChange={(e) => {
											let i = new Image();

											//	if need to restrict image size, use this code.
											// i.onload = function () {
											// 	// alert( i.width+", "+i.height );
											// };

											let reader = new FileReader();
											reader.readAsDataURL(
												e!.target!.files![0]
											);
											reader.onload = function () {
												setProductImage(
													reader.result as string
												);
												setProductImagePreview(
													reader.result as string
												);
												i.src = reader.result as string;
											};
										}}
									/>
								</Button>
							</CardContent>
							<CardActions
								sx={{
									justifyContent: 'flex-end',
									pr: 2,
									pb: 3,
								}}
							>
								<Button
									variant="contained"
									onClick={() => {
										updateProductInfo(
											productInfo.id,
											productName,
											maintenancePeriod,
											qandaUrl,
											instructionsUrl,
											detailedUrl,
											productImage
										).then(() => {
											handleClose();
											refetch();
										});
									}}
								>
									{<Intl>common.submit</Intl>}
								</Button>
							</CardActions>
						</Card>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default UpdateProductInfo;
