import React from 'react';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Intl } from 'components';

const SignoutButton = () => {
	const { logout } = useAuth0();
	return (
		<Button
			variant="contained"
			color="error"
			onClick={() =>
				logout({
					returnTo: window.location.origin,
				})
			}
		>
			<Intl>user.signout</Intl>
		</Button>
	);
};

export default SignoutButton;
