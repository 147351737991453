import React from 'react';
import './index.css';

const GenerateQRCode = () => {
	return (
		<>
			<div>GenerateQRCode</div>
		</>
	);
};

export default GenerateQRCode;
