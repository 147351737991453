import { useState } from 'react';
import { useExternalApi } from 'utils';
import { AxiosRequestConfig } from 'axios';

const GetMessageAPIs = () => {
	const apiServerUrl = process.env.REACT_APP_SERVER_URL;

	const { makeRequest } = useExternalApi();

	const [apiEndpoint, setApiEndpoint] = useState('');
	const [apiResponse, setApiResponse] = useState(
		'Click a button to make an API request...'
	);

	const getPublicResource = async (): Promise<void> => {
		setApiEndpoint('GET /api/echo/public');

		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/echo/public`,
			method: 'GET',
			headers: {
				'content-type': 'application/json',
			},
		};

		const { data } = await makeRequest({ config });

		setApiResponse(JSON.stringify(data, null, 2));
	};

	const getProtectedResource = async (): Promise<void> => {
		setApiEndpoint('GET /api/echo/protected');

		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/echo/protected`,
			method: 'GET',
			headers: {
				'content-type': 'application/json',
			},
		};

		const { data } = await makeRequest({ config, authenticated: true });

		setApiResponse(JSON.stringify(data, null, 2));
	};

	const getRbacResource = async (): Promise<void> => {
		setApiEndpoint('GET /api/echo/private');

		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/echo/private`,
			method: 'GET',
			headers: {
				'content-type': 'application/json',
			},
		};

		const { data } = await makeRequest({ config, authenticated: true });

		setApiResponse(JSON.stringify(data, null, 2));
	};

	return {
		apiEndpoint,
		apiResponse,
		getPublicResource,
		getProtectedResource,
		getRbacResource,
	};
};

export default GetMessageAPIs;
