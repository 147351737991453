import React from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading, AppContent } from 'components';
import { useTranslation } from 'react-i18next';

function App() {
	const { isLoading } = useAuth0();
	const { t } = useTranslation();
	React.useEffect(() => {
		document.title = t('default.title');
	});

	if (isLoading) {
		return <Loading />;
	}
	return <AppContent />;
}

export default withAuthenticationRequired(App, {
	onRedirecting: () => <Loading />,
});
