import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from 'components';

function ProtectRoute({ component }: any) {
	return withAuthenticationRequired(component, {
		onRedirecting: () => <Loading />,
		loginOptions: {
			ui_locales: localStorage.getItem('i18nextLng')!,
		},
	});
}
class ProtectedRoute extends React.Component<any, any> {
	render() {
		const ProtectedRouteComponent = ProtectRoute({
			component: this.props.component,
		});
		return (
			<>
				<Box sx={{ width: '100%' }}>
					<Typography variant="h3" component="div">
						{this.props.label}
					</Typography>
					<Divider sx={{ mt: 1, mb: 2 }} />
					<ProtectedRouteComponent />
				</Box>
			</>
		);
	}
}

export default ProtectedRoute;
