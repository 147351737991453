import React, { useState, useEffect, ChangeEvent } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { ProductAPIs } from 'api';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import {
	Intl,
	UpdateProductInfo,
	CreateProductBatch,
	DownloadQRFile,
} from 'components';
import { ProductProperties } from 'types';

const progress = (
	<>
		<Box sx={{ width: '100%' }}>
			<LinearProgress />
		</Box>
	</>
);

const getProdNameByLang = (prodName: object) => {
	let lang = localStorage.getItem('i18nextLng')!;
	lang = lang === 'en' ? 'en_US' : lang.replace('-', '_');
	return prodName[lang as keyof typeof getProdNameByLang];
};

const filterProduct = (
	nameHint: string = '',
	dtHint: string = '',
	rawProdList: Map<string, ProductProperties>
) => {
	const productArray = Array.from(rawProdList).filter(([key, value]) => {
		const prodNames: Array<string> = Object.values(
			JSON.parse(value.prodName)
		);
		return prodNames.find((n) => {
			return n.toLowerCase().includes(nameHint.toLowerCase());
		});
	});
	const returnMap = new Map<string, ProductProperties>();
	productArray.forEach(([key, value]) => {
		returnMap.set(key, value);
	});
	return returnMap;
};

const Row = (props: { row: ProductProperties; refetch: any }) => {
	const { row, refetch } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<TableRow sx={{ '& td, & div': { borderBottom: 'unset' } }}>
				<Stack direction="row" component="td">
					<TableCell component="div">
						<IconButton
							aria-label="expand row"
							size="large"
							onClick={() => setOpen(!open)}
						>
							{open ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</IconButton>
					</TableCell>
					<TableCell component="div">
						<Stack direction="column">
							<Typography variant="h6">
								{getProdNameByLang(JSON.parse(row.prodName))}
							</Typography>
							<Chip
								size="small"
								label={row.batches.size + ' Batches of QR'}
								sx={{ width: 'max-content' }}
							/>
						</Stack>
					</TableCell>
				</Stack>
				<TableCell align="right">{row.createDate}</TableCell>
				<TableCell align="right">
					<UpdateProductInfo
						productInfo={{
							id: row.id,
							prodName: JSON.parse(row.prodName),
							txt: row.txt,
							img: row.img,
						}}
						refetch={refetch}
					/>
					<CreateProductBatch prodId={row.id} refetch={refetch} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={6}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Paper elevation={1} sx={{ margin: 3 }}>
							<TableContainer>
								<Table size="small" aria-label="QR Batches">
									<TableHead>
										<TableRow>
											<TableCell>
												{
													<Intl>
														product.batch.number
													</Intl>
												}
											</TableCell>
											<TableCell align="right">
												{
													<Intl>
														common.create.date
													</Intl>
												}
											</TableCell>
											<TableCell align="right">
												{
													<Intl>
														common.update.date
													</Intl>
												}
											</TableCell>
											<TableCell align="right" />
										</TableRow>
									</TableHead>
									<TableBody>
										{Array.from(row.batches).map(
											([key, value]) => (
												<TableRow key={value.id}>
													<TableCell
														component="th"
														scope="row"
													>
														{getProdNameByLang(
															JSON.parse(
																value.batchNum
															)
														)}
													</TableCell>
													<TableCell align="right">
														{value.createDate}
													</TableCell>
													<TableCell align="right">
														{value.updateDate}
													</TableCell>
													<TableCell align="right">
														<DownloadQRFile
															batchId={value.id}
														/>
													</TableCell>
												</TableRow>
											)
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const ProductList = () => {
	const { findAllProduct, cancelRequest } = ProductAPIs();
	const { user } = useAuth0();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [querying, setQuerying] = useState(false);
	const [nameHint, setNameHint] = useState('');

	useEffect(() => {
		return () => {
			cancelRequest();
		};
		//	Keep deps as [] will make useEffect run only once.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {}, [nameHint]);

	const { data, refetch } = useQuery(
		['productListData'],
		async () => {
			setQuerying(true);
			const resData = await findAllProduct(user!['company']);
			setQuerying(false);
			return resData;
		},
		{ refetchOnWindowFocus: false }
	);

	const productListData = data
		? filterProduct(nameHint, '', data)
		: new Map<string, ProductProperties>();

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	return (
		<>
			<Box sx={{ pb: 2 }}>
				<TextField
					id="search"
					label={<Intl>product.search.label</Intl>}
					type="search"
					onChange={(e) => setNameHint(e.target.value)}
				/>
			</Box>
			<Paper elevation={5}>
				<TableContainer>
					<Table aria-label="Products">
						<TableBody>
							{productListData.size === 0 && querying ? (
								<TableRow>
									<TableCell>{progress}</TableCell>
								</TableRow>
							) : (
								Array.from(productListData)
									.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
									.map(([key, value]) => {
										return (
											<Row
												key={value.id}
												row={value}
												refetch={refetch}
											/>
										);
									})
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					labelRowsPerPage={<Intl>product.rows.per.page</Intl>}
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={productListData.size}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	);
};

export default ProductList;
