import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import './index.css';
import { ProductAPIs } from 'api';
import { Intl } from 'components';
import { useAuth0 } from '@auth0/auth0-react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const CreateProduct = () => {
	const { createNewProduct } = ProductAPIs();

	const { user } = useAuth0();

	const [productName, setProductName] = useState('');
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Box
				sx={{
					width: '100%',
				}}
			>
				<Card sx={{ maxWidth: 500 }} elevation={5}>
					<CardContent>
						<Typography
							sx={{ fontSize: 14, mb: 3 }}
							color="text.secondary"
						>
							{<Intl>product.create.instruction</Intl>}
						</Typography>
						<TextField
							fullWidth
							id="outlined-product-name"
							label={<Intl>product.props.name</Intl>}
							variant="outlined"
							value={productName}
							// onChange={handleChange}
							onChange={(e) => setProductName(e.target.value)}
						/>
					</CardContent>
					<CardActions sx={{ justifyContent: 'flex-end' }}>
						<Button
							onClick={() => {
								setOpen(true);
								createNewProduct(
									user!['company'],
									productName
								).then(() => {
									setOpen(false);
								});
							}}
						>
							{<Intl>common.submit</Intl>}
						</Button>
					</CardActions>
				</Card>
			</Box>

			<Backdrop
				sx={{
					color: '#fff',
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={open}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default CreateProduct;

//	Field value checking?
//	Button spin or backdrop during creation?
