import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => (
	<Box
		sx={{
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			zIndex: (theme) => theme.zIndex.drawer + 2,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			overflow: 'hidden',
			background: '#ffffff',
		}}
	>
		<CircularProgress
			size="40vw"
			sx={{ color: 'var(--accessreal-theme-color)' }}
		/>
	</Box>
);

export default Loading;
