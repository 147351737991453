import { languageResources } from 'locales';

// Bosnian	bs
// Bulgarian	bg
// Croatian	hr
// Czech	cs
// Chinese (Simplified)	zh-CN
// Chinese (Traditional)	zh-TW
// Danish	da
// Dutch	nl
// English	en
// Estonian	es
// Finnish	fi
// French	fr
// French (Canada)	fr-CA
// German	de
// Greek	el
// Hindi	hi
// Hungarian	hu
// Indonesian	id
// Italian	it
// Icelandic	is
// Latvian	lv
// Lithuanian	lt
// Japanese	ja
// Korean	ko
// Norwegian	nb
// Polish	pl
// Portuguese (Brazilian)	pt-BR
// Portuguese (Portugal)	pt-PT
// Romanian	ro
// Russian	ru
// Serbian	sb
// Slovak	sk
// Slovenian	sl
// Spanish	es
// Swedish	sv
// Thai	th
// Vietnamese	vi
// Ukrainian	uk
// Turkish	tr

const defaultLanguage = 'en';

const ProjectConfigs = {
	defaultLanguage: defaultLanguage,
	i18nDetectionOption: {
		order: ['localStorage', 'navigator'],
	},
	i18nFallBackLanguage: {
		'zh-SG': ['zh-CN'],
		'zh-HK': ['zh-TW'],
		default: [defaultLanguage],
	},
	i18nLanguageResources: languageResources,
};

export default ProjectConfigs;
