import React from 'react';
import './index.css';
// import { Nav } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';

class SideBarSubitem extends React.Component<any, any> {
	render() {
		return (
			<>
				{/*<Nav.Link*/}
				{/*	as={NavLink}*/}
				{/*	to={this.props.href}*/}
				{/*	href={this.props.href}*/}
				{/*	className="sidebar-subitem-button"*/}
				{/*>*/}
				{/*	{this.props.label}*/}
				{/*</Nav.Link>*/}
			</>
		);
	}
}

export default SideBarSubitem;
