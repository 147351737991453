import React from 'react';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Intl } from 'components';

const LoginButton = () => {
	const { loginWithRedirect } = useAuth0();
	return (
		<Button
			variant="contained"
			onClick={() =>
				loginWithRedirect({
					ui_locales: localStorage.getItem('i18nextLng')!,
				})
			}
		>
			<Intl>user.login</Intl>
		</Button>
	);
};

export default LoginButton;
