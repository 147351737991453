import React from 'react';
import './index.css';

class SideBarItem extends React.Component<any, any> {
	render() {
		return (
			<>
				<li>
					<div id={'heading_' + this.props.itemName}>
						<button
							className="accordion-button sidebar-item-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target={'#collapse_' + this.props.itemName}
							aria-expanded="false"
							aria-controls={'collapse_' + this.props.itemName}
						>
							{this.props.label}
						</button>
					</div>
					<div
						id={'collapse_' + this.props.itemName}
						className="accordion-collapse collapse"
						aria-labelledby={'heading_' + this.props.itemName}
						data-bs-parent="#sidebar_menu"
					>
						<div className="accordion-body p-1">
							{this.props.component}
						</div>
					</div>
				</li>
			</>
		);
	}
}

export default SideBarItem;
