import { useExternalApi } from 'utils';
import { AxiosRequestConfig } from 'axios';
import { useSnackbar } from 'notistack';
import { BatchProperties, ProductProperties } from 'types';

const ProductAPIs = () => {
	const apiServerUrl = process.env.REACT_APP_SERVER_URL;

	const { makeRequest, cancelRequest } = useExternalApi();
	const { enqueueSnackbar } = useSnackbar();

	const getEcho = async (): Promise<void> => {
		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/productinfo/echo`,
			method: 'GET',
			headers: {
				'content-type': 'application/json',
			},
		};

		await makeRequest({ config });
	};

	const createNewProduct = async (
		companyId: string,
		productName: string
	): Promise<void> => {
		enqueueSnackbar('Request Submitted.', { variant: 'info' });

		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/business/createNewProduct`,
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			data: {
				category_id: companyId,
				product_name: productName,
			},
		};

		const { data } = await makeRequest({ config, authenticated: true });

		if (data.code === 0) {
			enqueueSnackbar('Product Created!', { variant: 'success' });
		} else if (data.code === -4) {
			enqueueSnackbar('Product Duplicated!', { variant: 'error' });
		} else {
			enqueueSnackbar('Unknown Error!', { variant: 'error' });
		}
	};

	const findAllProduct = async (
		companyId: string
	): Promise<Map<string, ProductProperties>> => {
		enqueueSnackbar('Finding Products...', { variant: 'info' });

		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/business/findAllProduct`,
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			data: {
				category_id: companyId,
			},
		};

		const { data, message } = await makeRequest({
			config,
			authenticated: true,
		});

		if (message) {
			enqueueSnackbar(message, { variant: 'warning' });
			return new Map<string, ProductProperties>();
		}

		if (
			typeof data !== 'object' ||
			!data.hasOwnProperty('code') ||
			!data.hasOwnProperty('data') ||
			data.code !== 0
		) {
			enqueueSnackbar('Unknown Error!', { variant: 'error' });
			return new Map<string, ProductProperties>();
		}

		const pMap = new Map<string, ProductProperties>();

		data.data.forEach((raw_prod_props: object) => {
			const productProperties: Partial<ProductProperties> =
				raw_prod_props;

			const bMap = new Map<string, BatchProperties>();
			const raw_batches = raw_prod_props[
				'batches' as keyof typeof raw_prod_props
			] as Array<object>;

			raw_batches.forEach((raw_batch_props: object) => {
				bMap.set(
					raw_batch_props['id' as keyof typeof raw_batch_props],
					raw_batch_props as BatchProperties
				);
			});

			productProperties.batches = bMap;

			pMap.set(
				raw_prod_props['id' as keyof typeof raw_prod_props],
				productProperties as ProductProperties
			);
		});

		enqueueSnackbar(pMap.size + ' products found!', { variant: 'success' });

		return pMap;
	};

	const updateProductInfo = async (
		prodId: string,
		productName: string,
		maintenancePeriod: string,
		qandaUrl: string,
		instructionsUrl: string,
		detailedUrl: string,
		productImage: string
	): Promise<void> => {
		enqueueSnackbar('Request Submitted.', { variant: 'info' });

		const config: AxiosRequestConfig = {
			url: `${apiServerUrl}/api/productinfo/updateProductInfo`,
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			data: {
				product_id: prodId,
				name: productName,
				maintenance_period: maintenancePeriod,
				qanda_url: qandaUrl,
				instructions_url: instructionsUrl,
				detailed_url: detailedUrl,
				img: productImage ? productImage : null,
			},
		};

		const { data } = await makeRequest({ config, authenticated: true });

		if (data.code === 0) {
			enqueueSnackbar('Product Detail Updated!', { variant: 'success' });
		} else {
			enqueueSnackbar('Unknown Error!', { variant: 'error' });
		}
	};

	return {
		getEcho,
		createNewProduct,
		findAllProduct,
		updateProductInfo,
		cancelRequest,
	};
};

export default ProductAPIs;
