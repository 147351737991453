import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const useExternalApi = () => {
	const { getAccessTokenSilently } = useAuth0();
	let source = axios.CancelToken.source();

	const makeRequest = async (options: {
		config: AxiosRequestConfig;
		authenticated?: boolean;
	}) => {
		try {
			if (options.authenticated) {
				const token = await getAccessTokenSilently();

				options.config.headers = {
					...options.config.headers,
					Authorization: `Bearer ${token}`,
				};
			}

			options.config = {
				...options.config,
				cancelToken: source.token,
			};
			const response: AxiosResponse = await axios(options.config);
			return response;
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				return error.response.data;
			}
			return error as Error;
		}
	};

	const cancelRequest = () => {
		source.cancel('Request Cancelled.');
	};

	return {
		makeRequest,
		cancelRequest,
	};
};

export default useExternalApi;
